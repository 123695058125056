// import ProductFeatureImg from './productFeatureImg';
import { Typography } from 'antd';
import utils from '../../utils';
import '../styles.scss'

const {Title,Text} = Typography;

const Marcas = (props) => {
  const cnColsM = "grid grid-cols-1";
  return (
    <div className='bg-white'>
      <div className="container mx-auto mt-10">
        <div className="row mb-10">
          <br/>
          <Title level={2} className='font-roboto text-center'>SOMOS DISTRIBUIDORES OFICIALES</Title>
          <Title level={4} className='text-center'>Somos Distribuidores Oficiales de las principales marcas dentro del sector, lo que nos convierte en un proveedor de soluciones completo y de primer nivel para cada proceso de Soldadura y Corte.</Title>
        </div>
        {props.datamarcas && props.datamarcas.map((marca, index) => {
          return (
            <div className="card card-product card-plain min-h-40" key={index}>
              <div className={props.isMobile ? cnColsM : "grid grid-cols-2"}>
                {!utils.esPar(index) && !props.isMobile &&
                  <div className='fill'>
                    <img src={require(`../../assets/images/${marca.thumb_src}`)} alt="!231" />
                  </div>
                }
                <div className='mr-10 ml-10 mt-10'>
                  <div className="row">
                    <img src={require(`../../assets/images/${marca.image.src_image}`)} alt={marca.image.alt_image} style={{ width: 200, height: 100, verticalAlign: 'middle', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }} />
                    <Title level={3} className="mb-3 text-center">{marca.title}</Title>
                    <Text className="mb-5 text-justify">{marca.description}</Text>
                  </div>
                </div>
                {utils.esPar(index) && !props.isMobile &&
                  <div className='fill'>
                    <img src={require(`../../assets/images/${marca.thumb_src}`)} alt="!231" />
                  </div>
                }
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default Marcas;