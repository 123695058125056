import { Carousel } from 'antd';
import React from 'react';
import '../styles.scss';

// const contentStyle = {
//   margin: 0,
//   height: '95vh',
//   width: '100%',
//   color: '#fff',
//   lineHeight: '160px',
//   textAlign: 'center',
//   background: '#364d79',
// };

const PromoSectionLarge = () => {

  return (
    <Carousel effect="fade" autoplay>
      <div className="px-1 flex flex-1 justify-center">
          <div className="layout-content-container flex flex-col max-w-[100vw] flex-1">
            <div className="@container">
              <div className="@[480px]:p-4">
                <div className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded-xl items-start justify-end px-4 pb-10 @[480px]:px-10" style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("'+require('assets/imgs/banner2.png')+'")' }}>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div className="px-1 flex flex-1 justify-center">
          <div className="layout-content-container flex flex-col max-w-[100vw] flex-1">
            <div className="@container">
              <div className="@[480px]:p-4">
                <div className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded-xl items-start justify-end px-4 pb-10 @[480px]:px-10" style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("'+require('assets/imgs/RMB-banner.jpg')+'")' }}>
                  <h1 className="text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] text-center">
                    Destribuidor oficial
                  </h1>
                  <h2 className="text-white text-2xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] text-center">
                    Equipamientos en soldadura y corte
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-1 flex flex-1 justify-center">
          <div className="layout-content-container flex flex-col max-w-[100vw] flex-1">
            <div className="@container">
              <div className="@[480px]:p-4">
                <div className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded-xl items-start justify-end px-4 pb-10 @[480px]:px-10" style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("'+require('assets/imgs/prubabanner1.png')+'")' }}>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-1 flex flex-1 justify-center">
          <div className="layout-content-container flex flex-col max-w-[100vw] flex-1">
            <div className="@container">
              <div className="@[480px]:p-4">
                <div className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded-xl items-start justify-end px-4 pb-10 @[480px]:px-10" style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("'+require('assets/images/volt-hero1.jpg')+'")' }}>
                  <h1 className="text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] text-center">
                    TECNOLOGIA Y AUTOMATIZACION
                  </h1>
                  <h2 className="text-white text-2xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] text-center">
                    En soldadura y corte
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-1 flex flex-1 justify-center">
          <div className="layout-content-container flex flex-col max-w-[100vw] flex-1">
            <div className="@container">
              <div className="@[480px]:p-4">
                <div className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded-xl items-start justify-end px-4 pb-10 @[480px]:px-10" style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("'+require('assets/imgs/distrioficialesab.png')+'")' }}>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-1 flex flex-1 justify-center">
          <div className="layout-content-container flex flex-col max-w-[100vw] flex-1">
            <div className="@container">
              <div className="@[480px]:p-4">
                <div className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded-xl items-start justify-end px-4 pb-10 @[480px]:px-10" style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("'+require('assets/imgs/tyrolitbanner.png')+'")' }}>
                  <h1 className="text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] text-center">
                    TECNOLOGIA Y AUTOMATIZACION
                  </h1>
                  <h2 className="text-white text-2xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] text-center">
                    En soldadura y corte
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
    </Carousel>
  );
};
export default PromoSectionLarge;