
import React from "react";
import { Grid, Dropdown, Button } from 'antd';
import { CustomImg } from 'utils/images';
import { MenuOutlined } from '@ant-design/icons';
import utils from "utils";
import { useNavigate } from "react-router-dom";

const { useBreakpoint } = Grid;
const texto = "https://api.whatsapp.com/send/?phone=";

export const RenderHeader = () => {
  const navigate = useNavigate()
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');

  return (
    <header className="sticky top-0 z-40">
      <title>DS Ingenieria y Servicios</title>
      <div className="border-b bg-white/70 py-3 backdrop-blur-2xl">
        <nav className="container grid grid-cols-9 items-center">
          <a href="/"><img src={CustomImg.Logo} alt="img" width={85} height={83} style={{ verticalAlign: 'middle', marginLeft: 'auto', marginRight: 'auto' }} /></a>
          <div className="col-span-3">
          </div>
          <div></div>
          {isMobile ?
            <>
              <div></div>
              <div></div>
              <div>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '0',
                        label: <span>{"Inicio"}</span>,
                      },
                      {
                        key: '1',
                        label: <span>{"Soluciones"}</span>,
                      },
                      {
                        key: '2',
                        label: <span>{"Nosotros"}</span>,
                      },

                    ],
                    onClick: ({ key }) => {
                      switch (key) {
                        case "0": navigate("/")
                          break;
                        case "1": navigate("/soluciones")
                          break;
                        case "2": navigate("/nosotros")
                          break;
                        default: console.log("NADA")
                      }
                    }
                  }}
                >
                  <MenuOutlined />
                </Dropdown>
              </div>
            </>
            :
            <>
              <h3
                className="flex justify-center font-semibold text-gray-900 sm:text-m"
                onClick={() => navigate("/")}
                style={{ cursor: 'pointer' }}
              >
                <Button type="link" style={{ color: "black" }} onClick={() => navigate("/")}>Inicio</Button>
              </h3>
              <h3
                className="flex justify-center font-semibold text-gray-900 sm:text-m"
                onClick={() => navigate("/soluciones")}
                style={{ cursor: 'pointer' }}
              >
                <Button type="link" style={{ color: "black" }} /*href="/soluciones"*/ onClick={() => navigate("/soluciones")}>Soluciones</Button>
              </h3>
              <h3
                className="flex justify-center font-semibold text-gray-900 sm:text-m"
                onClick={() => navigate("/nosotros")}
                style={{ cursor: 'pointer' }}
              >
                <Button type="link" style={{ color: "black" }} /*href="/nosotros"*/ onClick={() => navigate("/nosotros")}>Nosotros</Button>
              </h3>
            </>

          }
          {!isMobile && <div><Button type="primary"
            onClick={() => window.open(`${texto}5493482621794&text=Hola.%20Quiero%20solicitar%20una%20cotizaci%C3%B3n.`)}
          >Contacto</Button> </div>}
        </nav>
      </div>
      {/* <div className={styles.header}>
        <a href="/"><img src={CustomImg.Logo} alt="img" width={35} height={33} style={{ verticalAlign: 'middle' }} /></a>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Buscar"
          className={styles.inputField}
        />
        <h3
          className="flex justify-center font-semibold text-gray-900 sm:text-m"
        >
          <a href="/">Inicio</a>
        </h3>
      </div> */}
    </header>
  );
};



