import { ContentSection } from "views/dashboard";
import SectionCatalogo from 'views/catalogo';
import SectionSoluciones from 'views/soluciones';
import SectionNosotros from 'views/nosotros'

export const nav = [
     //PUBLIC
     { path: "/", name: "Inicio", title: "Inicio", element: <ContentSection />, isMenu: false, isPrivate: false },
     { path: "/catalogo", name: "Catálogo", title: "Catálogo", element: <SectionCatalogo />, isMenu: false, isPrivate: false },
     { path: "/soluciones", name: "Soluciones", title: "Soluciones", element: <SectionSoluciones />, isMenu: false, isPrivate: false },
     { path: "/nosotros", name: "Nosotros", title: "Nosotros", element: <SectionNosotros />, isMenu: false, isPrivate: false },

     //PRIVATE
     // { path: "/profile", name: "Profile", element: <ProfileComponent />, isMenu: false, isPrivate: true },
]