import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { App as AppAntD, ConfigProvider } from 'antd';
import { AuthWrapper } from './Layout';
import { IntlProvider } from 'react-intl';
import AppLocale from 'lang/intl';
import { THEME_CONFIG } from 'config/UserConfig';
import './lang'

import './App.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: '#0a0a0a',
                colorPrimaryHover: '#1a1919',
                algorithm: true, // Enable algorithm
              },
            }
          }}
        >
          <IntlProvider locale={AppLocale[THEME_CONFIG.locale].locale} messages={AppLocale[THEME_CONFIG.locale].messages}>
            <AppAntD>
              <h1 className="mb-2 text-6xl font-large leading-tight text-center hidden">
                DS INGENIERIA Y SERVICIOS
              </h1>
              <title className="hidden">
                DS INGENIERIA Y SERVICIOS
              </title>
              <AuthWrapper />
            </AppAntD>
          </IntlProvider>
        </ConfigProvider>

      </BrowserRouter>
    </div>
  );
}

export default App;