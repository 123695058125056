import { useEffect } from 'react';
import { Col, Grid, Row } from 'antd';
import Marcas from '../../components/products/MarcasSection.jsx';
import data from 'assets/data.json';
import Image from '../../components/img';
import Carousel from "react-multi-carousel";
import Volt from '../../assets/images/volt-hero1.jpg';
import utils from "utils";

//Styles
import '../style.css';
const styles = {
    pageHeader: {
        backgroundImage: `url(${Volt})`,
        minHeight: '90vh',
        borderRadius: '0px'
    },
}

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const { useBreakpoint } = Grid;

const Soluciones = () => {
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
    const cnColsM = "grid grid-cols-1 gap-1";
    useEffect(() => {
        document.title = " Soluciones"
    }, [])

    return (
        <div>
            <h1 className="mb-2 text-6xl font-large leading-tight text-center hidden">
          DS INGENIERIA Y SERVICIOS
        </h1>
        <title className="hidden">
          DS INGENIERIA Y SERVICIOS
        </title>
            <div className="page-header py-5 py-md-0" style={styles.pageHeader}>
                <span className="mask bg-black opacity-6"></span>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="absolute top-15 p-6 w-full ">
                            <p className="mb-2 text-6xl font-large leading-tight text-center">
                                NUESTRAS SOLUCIONES
                            </p>
                            <p className="mb-2 text-6xl font-large leading-tight text-center">
                                Mantenimiento y reparación de equipos
                            </p>
                            <p className="mb-4 text-xl text-center">
                                Todos nuestros productos y servicios cuentan con el respaldo técnico y comercial de las principales marcas dentro del sector de la Soldadura y el Corte
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' mb-10 '>
                <div className="ml-8 mr-8 mt-10">
                    <div className={isMobile ? cnColsM : "grid grid-cols-4 gap-2"}>
                        <div className="max-w-sm p-6">
                            <h4 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900">NUESTRAS SOLUCIONES</h4>
                            <p className="mb-3">
                                Nuestras soluciones se adaptan a cada desafío de Soldadura y Corte, buscando maximizar su Productividad y Rentabilidad a través de la mejora continua de procesos y recursos.
                            </p>
                        </div>
                        {data.soluciones.map((solucion, index) => (
                            <div className="ppbox max-w-sm p-6 border border-gray-200 rounded-lg shadow dark:border-gray-500" key={index}>
                                <p className="text-center text-rose-950"><i className={`${solucion.fa_icon} text-6xl text-center `}></i></p>
                                <h4 className="mb-2 text-2xl font-semibold">{solucion.title}</h4>
                                <p className="mb-3 font-normal">{solucion.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <br />
                <Marcas
                    datamarcas={data.marcas}
                    isMobile={isMobile}
                />
                <br />
                <div className='container mx-auto px-4 pt-8 pb-8 px-12 d613de1f'>
                    <h3 className='text-center' style={{ fontWeight: 470, fontSize: '30px' }}><b>Servicio Post Venta</b></h3>
                    <p className='text-justify' style={{ fontWeight: 270, fontSize: '18px' }}>
                        El servicio de Post Venta tiene por objetivo primordial satisfacer y orientar a nuestros clientes.
                        <br />
                        Este valor agregado se pone de manifiesto a través de los servicios de seguimiento, reparación y mantención de equipos, asesorados por técnicos profesionales altamente capacitados que avalan las reparaciones en el menor tiempo, con el mejor costo de mantenimiento y con el propósito de brindar al cliente la calidad, la confiabilidad y el respaldo de una marca líder.
                        <br />
                        Ofrecemos:
                    </p>
                    <ul className="list-none md:list-disc" style={{ fontWeight: 270, fontSize: '18px' }}>
                        <li>Garantía</li>
                        <li>Repuestos legítimos y originales</li>
                        <li>Asistencia, reparación y mantenimiento</li>
                    </ul>
                </div>
                <br />
                <div className='bg-white'>
                    <div className='container mx-auto px-4 pt-8 pb-8 px-12 d613de1f'>
                        <p className='text-2xl text-center uppercase row-auto'>
                            Sobre nosotros
                        </p>
                        <br />
                        <Row>
                    <Col span={24}>
                        {/* <img src={require('assets/imgs/pantografo.png')} alt="pantografo" /> */}
                        <Carousel
                            responsive={responsive}
                            infinite
                            // showDots={false}
                            containerClass="custom-dot-list-style"
                            itemClass="image-item aboutus"
                            autoPlaySpeed={5000}
                            autoPlay={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            deviceType='desktop'
                            arrows={false}
                            renderDotsOutside
                        >
                            {data.empresa.map((data, index) => {
                                return <Image key={index} url={require(`assets/empresa/${data.src_image}`)} alt={data.alt_image} width={'80vw'} height={'65vh'} cn={'aboutus unpoco'} />;
                            })}
                        </Carousel>
                    </Col>
                    <Col span={4}></Col>
                </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Soluciones