// import ProductFeatureImg from './productFeatureImg';
import '../styles.scss'
import '../hover_bw.css'
import Image from "../img";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
  };
  

const OurClients = (props) => {

    return (
        <article className="article pt-4 pb-7 pl-5 pr-5">
            <div className="container-fluid container-mw-xl pr-0 pl-0">
                <div className="col-md-12 text-center mb-1">
                    <h2 className='text-xl' style={{ textAlign: 'center', fontWeight: 470, fontSize: '30px' }}><b>Clientes que confían en nosotros</b></h2>
                </div>
                <br />
                <br />
                {/* Clientes */}
                <Carousel
                    responsive={responsive}
                    infinite
                    showDots
                    containerClass="custom-dot-list-style"
                    itemClass="image-item"
                    autoPlaySpeed={5000}
                    autoPlay={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    deviceType='desktop'
                // removeArrowOnDeviceType={["desktop"]}
                >
                    {props.clients?.map((data, index) => {
                        return <Image key={index} url={require(`../../assets/images/${data.image.src_image}`)} alt={data.image.alt_image} />;
                    })}
                </Carousel>
                {/* <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }} >
                    {props.clients.map((data) => (
                        <div key={data.link} style={{
                            minWidth: '185px', minHeight: '120px',
                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                            marginBottom:'15px',marginTop:'15px'
                        }}>
                            <div style={{ cursor: 'pointer' }} onClick={()=>window.open(data.link,"_blank")}>
                                <img style={{ maxWidth: '230px', maxHeight: '180px' }}
                                    src={require(`../../assets/images/${data.image.src_image}`)}
                                    alt={data.image.alt_image} className="img-fluid grayscale" />
                            </div>
                        </div>
                    ))
                    }
                </div> */}
            </div>
        </article>
    );
};

export default OurClients;