// import ProductFeatureImg from './productFeatureImg';
import { Typography, Grid } from 'antd';
import utils from "utils";
import '../styles.scss';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const MarcasSm = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const grids = isMobile ? "grid grid-cols-2 gap-2" : "grid grid-cols-6 gap-2";
  return (
    <div className='bg-white'>
      <div className="container mx-auto mt-10">
        <div className="row mb-10">
          <br />
          <h2 className='text-xl' style={{ textAlign: 'center', fontWeight: 470, fontSize: '30px' }}><b>SOMOS DISTRIBUIDORES OFICIALES</b></h2>
        </div>

        <div className={grids}>
          {props.datamarcas?.map((marca, index) => {
            return (
              <img
                key={index}
                src={require(`../../assets/images/${marca.image.src_image}`)}
                alt={marca.image.alt_image}
                style={{ width: 200, height: 100, verticalAlign: 'middle', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }} />
            )
          })}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default MarcasSm;