import { createContext } from "react";
import { Layout } from 'antd';
import ReactWhatsappButton from "react-whatsapp-button";
import styled from '@emotion/styled';
import { RenderHeader } from "./Header";
import { RenderFooter } from "./Footer";
import { RenderRoutes } from './RenderNavigation'
import Footer from "../components/Footer";
import { TEMPLATE, MEDIA_QUERIES } from 'constants/ThemeConstant';

const AuthContext = createContext();

const AppContent = styled('div')`
    /*padding: 0px ${TEMPLATE.LAYOUT_CONTENT_GUTTER}px;*/
    /*margin-top: ${TEMPLATE.HEADER_HEIGHT}px;*/
    min-height: calc(100vh - ${TEMPLATE.CONTENT_HEIGHT_OFFSET}px);
    position: relative;

    ${props => props.isNavTop ? `
        max-width: ${TEMPLATE.CONTENT_MAX_WIDTH}px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @media ${MEDIA_QUERIES.DESKTOP} { 
            /*margin-top: ${TEMPLATE.HEADER_HEIGHT + TEMPLATE.TOP_NAV_HEIGHT}px;*/
            min-height: calc(100vh - ${TEMPLATE.CONTENT_HEIGHT_OFFSET}px - ${TEMPLATE.TOP_NAV_HEIGHT}px);
        }
    ` : ''}

    @media ${MEDIA_QUERIES.MOBILE} { 
        padding: ${TEMPLATE.LAYOUT_CONTENT_GUTTER_SM}px;
    }
`

export const AuthWrapper = () => {

  return (

    <AuthContext.Provider>
      <Layout id='header'>
        <AppContent>


          <RenderHeader />
          <RenderRoutes />
          <ReactWhatsappButton
            countryCode="54"
            phoneNumber="93482299277"
            message="Quiero solicitar una cotización."
            animated
          />
          <Footer />
          <RenderFooter />
        </AppContent>
      </Layout>

    </AuthContext.Provider>

  )

}