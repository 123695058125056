// import Icon from '@ant-design/icons';
// import ArgIcon from '../../../../assets/svgs/flags/argentina.svg';
import '../../../style.css'


// const ArgFlag = () => <Icon component={ArgIcon}/>;


const OurClients = (props) => {

    return (
        <div className='bg-white aboutmini'>
            <div className='container mx-auto px-4 pt-8 pb-8 px-12 d613de1f'>
                {/* <p> */}
                    <h3 className='text-xl text-center uppercase row-auto'>
                            <b>Somos una empresa dedicada a dar SOLUCIONES INDUSTRIALES en soldadura y corte.</b>
                    </h3>
                    <br />
                    <h3 className='text-xl text-center'><b>NUESTRAS SOLUCIONES</b></h3>
                    <br />
                    <ul className="list-none md:list-disc">
                        <li className='text-lg'>
                            Venta de equipos, insumos, consumibles y repuetos de alta tecnología, idóneos para cada proyecto.
                        </li>
                        <br/>
                        <li className='text-lg'>
                            Automatización Industrial en procesos de soldadura y corte láser.
                        </li>
                        <br/>
                        <li className='text-lg'>
                            Servicio técnico oficial e integrado: Puesta en marcha, demostraciones, capacitaciones, controles preventivos y correctivos de equipos.
                        </li>
                        <br/>
                        <li className='text-lg'>
                            Fabricación de mesas automatizadas de corte por plasma, con tecnología HYPERTHERM (Pantógrafo).
                        </li>
                        <br/>
                        <li className='text-lg'>
                            Consultoría técnica industrial: evaluamos y acompañamos nuevos proyectos para mejorar los procesos productivos de nuestros clientes.
                        </li>
                    </ul>
                {/* </p> */}
            </div>
        </div>
    );
};

export default OurClients;