import React, { useEffect } from "react";
import { Divider } from "antd";
import { Content } from "antd/es/layout/layout";

//
import PromoSectionLarge from '../../components/promo/promoSectionLarge.jsx';
import OurClients from '../../components/products/OurClients.jsx';
import MarcasSm from "components/products/MarcasSm.jsx";
import AboutMini from "./Section/Aboutmini";
import SomeClients from "./Section/SomeClients";
import data from 'assets/data.json';

//Styles
import '../style.css';

export const ContentSection = () => {

  useEffect(() => {
    document.title = "Inicio"
  }, [])
  // return (
  //   <div>
  //     <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
  //     <link rel="stylesheet" as="style" onload="this.rel='stylesheet'" href="https://fonts.googleapis.com/css2?display=swap&family=Noto+Sans%3Awght%40400%3B500%3B700%3B900&family=Work+Sans%3Awght%40400%3B500%3B700%3B900" />
  //     <title>Ds Ingenieria</title>
  //     <link rel="icon" type="image/x-icon" href="data:image/x-icon;base64," />
  //     <div className="relative flex size-full min-h-screen flex-col bg-[#fcf8f8] group/design-root overflow-x-hidden" style={{fontFamily: '"Work Sans", "Noto Sans", sans-serif'}}>
  //       <div className="layout-container flex h-full grow flex-col">

  //         <div className="px-40 flex flex-1 justify-center py-5">
  //           <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
  //             <div className="@container">
  //               <div className="@[480px]:p-4">
  //                 <div className="flex min-h-[480px] flex-col gap-6 bg-cover bg-center bg-no-repeat @[480px]:gap-8 @[480px]:rounded-xl items-start justify-end px-4 pb-10 @[480px]:px-10" style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("https://cdn.usegalileo.ai/sdxl10/7f58a82b-75ba-4e78-9fd6-79f6d3bea316.png")'}}>
  //                   <h1 className="text-white text-4xl font-black leading-tight tracking-[-0.033em] @[480px]:text-5xl @[480px]:font-black @[480px]:leading-tight @[480px]:tracking-[-0.033em] text-center">
  //                     Welding and Cutting Equipment
  //                   </h1>
  //                   <button className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 @[480px]:h-12 @[480px]:px-5 bg-[#c10b0b] text-[#fcf8f8] text-sm font-bold leading-normal tracking-[0.015em] @[480px]:text-base @[480px]:font-bold @[480px]:leading-normal @[480px]:tracking-[0.015em]">
  //                     <span className="truncate">Shop Now</span>
  //                   </button>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  return (
    <Content>
      <div>
        <h1 className="mb-2 text-6xl font-large leading-tight text-center hidden">
          DS INGENIERIA Y SERVICIOS
        </h1>
        <title className="hidden">
          DS INGENIERIA Y SERVICIOS
        </title>
        <PromoSectionLarge />
        <Divider />
        <MarcasSm
          datamarcas={data.marcas}
        />
        <Divider />
        <AboutMini />
        <Divider />
        <OurClients
          clients={data.ourclients}
        />

        <Divider />
        <SomeClients data={data.aboutus_images} />

      </div>
    </Content>
  );
};


