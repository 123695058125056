import React, { useEffect } from 'react'
import { PageHeader } from '@ant-design/pro-layout';
import { Divider, Pagination, Card } from 'antd';

const SectionCatalogo = ({ user }) => {
    //------ State --------------

    //------ Constantes --------------

    useEffect(() => {
        document.title = " Catálogo"
        // const fetch = async () => {}

        // fetch();
    }, []);


    //----- Return ---------------------
    return (
        <>
            <div key="1">
                <Card>
                <h1 className="mb-2 text-6xl font-large leading-tight text-center hidden">
          DS INGENIERIA Y SERVICIOS
        </h1>
        <title className="hidden">
          DS INGENIERIA Y SERVICIOS
        </title>
                    <div>
                        <PageHeader
                            onBack={() => window.history.back()}
                            title={"Templates"}
                        />
                        <Divider />
                    </div>
                    <div>
                        <Pagination
                            size="small"
                        />
                    </div>
                </Card>
            </div>
        </>
    )
}


export default SectionCatalogo;