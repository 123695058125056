
import React from "react";
import { Content, Footer } from "antd/es/layout/layout";
import { Row, Typography } from "antd";
import { FULL_YEAR } from "utils/constants";

const { Text } = Typography;

export const RenderFooter = () => {

  return (
    <Content>
      <Footer style={{padding:'1px 50px'}}>
        <Row>
          <Text style={{fontSize:'9px'}}>Create by Ip.Sistemas {FULL_YEAR}</Text>
        </Row>
      </Footer>
    </Content>
  );
};



