import { Col, Divider, Row } from 'antd';
// import Icon from '@ant-design/icons';
// import ArgIcon from '../../../../assets/svgs/flags/argentina.svg';
import Image from '../../../../components/img';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../../../style.css'


// const ArgFlag = () => <Icon component={ArgIcon}/>;

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const OurClients = (props) => {

    return (
        <div className='bg-white'>
            <div className='container mx-auto px-4 pt-8 pb-8 px-12 d613de1f'>
                <h2 className='text-xl' style={{ textAlign: 'center', fontWeight: 470, fontSize: '30px' }}><b>Sobre nosotros</b></h2>
                <Divider style={{ borderWidth: 'thin' }} />
                <Row>
                    <Col span={8} offset={3}>
                        <p className='text-justify' style={{fontSize:'16px'}}>
                            Es una empresa que desde hace más de 5 años se dedica a dar soluciones integrales y personalizadas en soldadura y corte metalmecánicos, que une tecnología, innovación y marcas de primer nivel para acompañar a nuestros clientes a alcanzar su máximo potencial.
                        </p>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={8}>
                        {/* <img src={require('assets/imgs/pantografo.png')} alt="pantografo" /> */}
                        <Carousel
                            responsive={responsive}
                            infinite
                            // showDots={false}
                            containerClass="custom-dot-list-style"
                            itemClass="image-item aboutus"
                            autoPlaySpeed={5000}
                            autoPlay={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            deviceType='desktop'
                            arrows={false}
                            renderDotsOutside
                        >
                            {props.data?.map((data, index) => {
                                return <Image key={index} url={require(`assets/imgs/${data.src_image}`)} alt={data.alt_image} width={'80vw'} height={'65vh'} cn={'aboutus'} />;
                            })}
                        </Carousel>
                    </Col>
                    <Col span={4}></Col>
                </Row>
            </div>
        </div>
    );
};

export default OurClients;