import { useEffect } from 'react';
import Ds from '../../assets/images/frenteds.jpg';

//Styles
import '../style.css';
import { Col, Divider, Row } from 'antd';
const styles = {
    pageHeader: {
        backgroundImage: `url(${Ds})`,
        minHeight: '70vh',
        borderRadius: '0px',
        backgroundSize: 'contain',
    },
}


const Soluciones = () => {

    useEffect(() => {
        document.title = "Nosotros"
    }, [])

    return (
        <div>
            <h1 className="mb-2 text-6xl font-large leading-tight text-center hidden">
                DS INGENIERIA Y SERVICIOS
            </h1>
            <title className="hidden">
                DS INGENIERIA Y SERVICIOS
            </title>
            <div className="page-header py-5 py-md-0" style={styles.pageHeader}>
                <span className="mask bg-black opacity-6"></span>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="absolute top-15 p-6 w-full ">
                        </div>
                    </div>
                </div>
            </div>
            <div className='container' style={{ margin: '5px 5px' }}>
                <section>
                    <div className="row-auto ml-8 mr-8 mt-10">
                        {/* <h2 className='text-6xl font-large leading-tight text-center wow fadeInDown animated'>Sobre Nosotros</h2> */}
                        <h2 className='text-xl font-large leading-tight text-center wow fadeInDown animated' style={{ textAlign: 'center', fontWeight: 470, fontSize: '30px' }}><b>Sobre nosotros</b></h2>
                    </div>
                    <Divider />
                    {/* <div className="row-auto ml-8 mr-8 mt-10"> */}
                    <Row>
                        {/* <Col span={{ span: { xs: 24, sm: 16, md: 16, lg: 16 } }}> */}
                        <Col offset={1} sm={24} md={24} lg={15} xl={15} xxl={15}>
                            <h2 className='text-4xl font-large leading-tight wow fadeInDown animated column-title'><b>DS Ingenieria y Servicios</b></h2>
                            <br /><br />
                            <p className="mb-3 font-normal wow fadeInDown animated text-justify">
                                Es una empresa que desde hace más de 5 años se dedica a dar soluciones integrales y personalizadas en soldadura y corte metalmecánicos, que une tecnología, innovación y marcas de primer nivel para acompañar a nuestros clientes a alcanzar su máximo potencial.
                                <br />
                                Mediante nuestro equipo técnico-comercial especializado evaluamos, diseñamos y ejecutamos como aliados estratégicos del cliente nuevos proyectos de mejora continua, buscando maximizar su productividad.
                                <br />
                                <br />
                                Actualmente desarrollamos 3 unidades de negocio:
                                <br />
                                Venta de equipos, insumos, consumibles y repuestos de alta tecnología, idóneos para cada proyecto.
                                <br />
                                Automatización Industrial en procesos de soldadura y corte.
                                <br />
                                Servicio técnico integral: Puesta en marcha, demostraciones, capacitaciones, controles preventivos y correctivos de equipos.
                                <br />
                                <br />
                                Nos encontramos en el Distrito de Industrias y Talleres de la ciudad de Reconquista, Santa Fe. Con nuestro proceso de logística distribuímos insumos a todas las plantas industriales del país.
                            </p>
                        </Col>
                        {/* <Col span={{ span: { xs: 24, sm: 8, md: 8, lg: 8 } }}> */}
                        <Col offset={1} sm={24} md={24} lg={5} xl={5} xxl={5}>
                            <h3 className='text-2xl font-large leading-tight wow fadeInDown animated column-title'><b>Visión:</b></h3>
                            <br />
                            <p className='text-justify'>Ser la principal referencia de Argentina en servicio integral y de calidad en rubros de soldadura y corte metalmecánico, mediante insumos, consumibles, equipos, soporte técnico y proyectos tecnológicos asociados.</p>

                            <h3 className='text-2xl font-large leading-tight wow fadeInDown animated column-title'><b>Misión:</b></h3>
                            <br />
                            <p className='text-justify'>Brindamos soluciones integrales, innovadoras y personalizadas en soldadura y corte metalmecánicos; como aliados estratégicos del cliente en la búsqueda de soluciones integradas entre insumos y tecnología, con liderazgo desarrollado y colaboradores motivados por los desafíos de la organización y a la vanguardia del negocio.</p>
                            <br />
                            <h3 className='text-2xl font-large leading-tight wow fadeInDown animated column-title column-title'><b>Valores:</b></h3>
                            <br />
                            <ul>
                                <li><p className='text-justify'>1 - <b>Compromiso:</b> acompañar a los clientes en la consecución de sus objetivos, impulsando la mejora continua de sus procesos productivos incluso ante situaciones adversas. </p></li>

                                <li><p className='text-justify'>2 - <b>Sinceridad:</b> En cada vínculo manifestar coherencia entre el decir y el actuar, para que los clientes puedan decidir de la mejor manera dentro de sus posibilidades.</p></li>

                                <li><p className='text-justify'>3 - <b>Profesionalismo:</b> Actuar de manera planificada, midiendo los riesgos y comunicando correctamente. Tendiendo a concretar los planes de manera progresiva y optimizar rendimientos.</p></li>

                                <li><p className='text-justify'>4 - <b>Competitividad:</b> Óptimo en conocimiento, innovación y capacidad para adaptarse ante las variaciones del contexto, generando nuevas oportunidades y soluciones en el rubro.</p></li>

                                <li><p className='text-justify'>5 - <b>Optimismo/esperanza:</b> Proyectar el futuro en su aspecto más favorable, trabajando para que eso suceda.</p></li>

                                <li><p className='text-justify'>6 - <b>Unión:</b>  Alinear esfuerzos para concretar proyectos y superar desafíos.</p></li>
                            </ul>
                        </Col>
                        {/* <div className="col-start-1 col-end-8">
                                <h2 className='text-4xl font-large leading-tight wow fadeInDown animated column-title'><b>DS Ingenieria y Servicios</b></h2>
                                <br /><br />
                                <p className="mb-3 font-normal wow fadeInDown animated text-justify">
                                    Es una empresa que desde hace más de 5 años se dedica a dar soluciones integrales y personalizadas en soldadura y corte metalmecánicos, que une tecnología, innovación y marcas de primer nivel para acompañar a nuestros clientes a alcanzar su máximo potencial. 
                                    <br/>
                                    Mediante nuestro equipo técnico-comercial especializado evaluamos, diseñamos y ejecutamos como aliados estratégicos del cliente nuevos proyectos de mejora continua, buscando maximizar su productividad. 
                                    <br/>
                                    <br/>
                                    Actualmente desarrollamos 3 unidades de negocio: 
                                    <br/>
                                    Venta de equipos, insumos, consumibles y repuestos de alta tecnología, idóneos para cada proyecto.
                                    <br/>
                                    Automatización Industrial en procesos de soldadura y corte.
                                    <br/>
                                    Servicio técnico integral: Puesta en marcha, demostraciones, capacitaciones, controles preventivos y correctivos de equipos.
                                    <br/>
                                    <br/>
                                    Nos encontramos en el Distrito de Industrias y Talleres de la ciudad de Reconquista, Santa Fe. Con nuestro proceso de logística distribuímos insumos a todas las plantas industriales del país.
                                </p>
                            </div>
                            <div className="col-start-8 col-end-12">
                                <h3 className='text-2xl font-large leading-tight wow fadeInDown animated column-title'><b>Visión:</b></h3>
                                <br />
                                <p className='text-justify'>Ser la principal referencia de Argentina en servicio integral y de calidad en rubros de soldadura y corte metalmecánico, mediante insumos, consumibles, equipos, soporte técnico y proyectos tecnológicos asociados.</p>

                                <h3 className='text-2xl font-large leading-tight wow fadeInDown animated column-title'><b>Misión:</b></h3>
                                <br />
                                <p className='text-justify'>Brindamos soluciones integrales, innovadoras y personalizadas en soldadura y corte metalmecánicos; como aliados estratégicos del cliente en la búsqueda de soluciones integradas entre insumos y tecnología, con liderazgo desarrollado y colaboradores motivados por los desafíos de la organización y a la vanguardia del negocio.</p>
                                <br />
                                <h3 className='text-2xl font-large leading-tight wow fadeInDown animated column-title column-title'><b>Valores:</b></h3>
                                <br />
                                <ul>
                                    <li><p className='text-justify'>1 - <b>Compromiso:</b> acompañar a los clientes en la consecución de sus objetivos, impulsando la mejora continua de sus procesos productivos incluso ante situaciones adversas. </p></li>
                                    
                                    <li><p className='text-justify'>2 - <b>Sinceridad:</b> En cada vínculo manifestar coherencia entre el decir y el actuar, para que los clientes puedan decidir de la mejor manera dentro de sus posibilidades.</p></li>
                                    
                                    <li><p className='text-justify'>3 - <b>Profesionalismo:</b> Actuar de manera planificada, midiendo los riesgos y comunicando correctamente. Tendiendo a concretar los planes de manera progresiva y optimizar rendimientos.</p></li>
                                    
                                    <li><p className='text-justify'>4 - <b>Competitividad:</b> Óptimo en conocimiento, innovación y capacidad para adaptarse ante las variaciones del contexto, generando nuevas oportunidades y soluciones en el rubro.</p></li>
                                    
                                    <li><p className='text-justify'>5 - <b>Optimismo/esperanza:</b> Proyectar el futuro en su aspecto más favorable, trabajando para que eso suceda.</p></li>
                                    
                                    <li><p className='text-justify'>6 - <b>Unión:</b>  Alinear esfuerzos para concretar proyectos y superar desafíos.</p></li>
                                </ul>
                            </div> */}
                    </Row>
                    {/* </div> */}

                </section>
            </div>
        </div>
    )
}
export default Soluciones